@import url('https://fonts.googleapis.com/css2?family=Lobster&family=Roboto:wght@400;700&display=swap');

.messagesContainer {
  font-family: 'Roboto', sans-serif;
  background-color: #006477; /* Deep teal */
  color: #eec4b4; /* Soft peach */
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

.messagesWrap {
  background: #ffffff;
  border-radius: 15px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  width: 90%;
  max-width: 600px;
  text-align: center;
}

.messagesWrap h1 {
  font-family: 'Lobster', cursive;
  font-size: 2em;
  color: #125c68;
  margin-bottom: 20px;
}

.messagesList {
  list-style-type: none;
  padding: 0;
  margin: 20px 0;
}

.messagesList li {
  background-color: #eec4b4;
  color: #125c68;
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.logoutButton {
  background-color: #eec4b4;
  color: #125c68;
  font-weight: bold;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.logoutButton:hover {
  background-color: #d5a896;
}
