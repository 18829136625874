@import url('https://fonts.googleapis.com/css2?family=Lobster&family=Roboto:wght@400;700&display=swap');

.loginContainer {
  font-family: 'Roboto', sans-serif;
  background-color: #125c68; /* Deep teal background */
  color: #eec4b4; /* Soft peach text */
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loginWrap {
  background: #ffffff;
  padding: 20px 30px;
  border-radius: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  text-align: center;
  width: 90%;
  max-width: 400px;
}

.loginWrap h1 {
  font-family: 'Lobster', cursive;
  font-size: 2em;
  color: #125c68;
  margin-bottom: 20px;
}

.loginWrap input {
  width: 100%;
  padding: 10px;
  margin: 10px 0;
  border: 1px solid #eec4b4;
  border-radius: 5px;
  box-sizing: border-box;
}

.loginWrap button {
  background-color: #eec4b4;
  color: #125c68;
  font-weight: bold;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  width: 100%;
  transition: background-color 0.3s;
}

.loginWrap button:hover {
  background-color: #d5a896;
}
